


















































/// <reference types="gtag.js" />
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Environment, EnvironmentUser, View, PaginatedQueryResult } from '@/models'
import Loading from '@/components/Loading.vue'
import EnvironmentSidebar from './Sidebar.vue'
import EnvironmentNotifications from './Notifications.vue'
import EnvironmentMessages from './Messages.vue'
import * as pathToRegexp from 'path-to-regexp'
import gql from 'graphql-tag'
import _mapKeys from 'lodash/mapKeys'

declare global {
  interface Window {
    RocketChat : any
  }
}

@Component({
  components: {
    Loading,
    EnvironmentSidebar,
    EnvironmentNotifications,
    EnvironmentMessages
  },
  apollo: {
    environment: {
      query: gql`query getEnvironment($url: String) {
        environment(url: $url) {
          _id
          name
          homeRedirect
          logo {
            url
          }
          icon {
            url
          }
          fontName
          accentColor
          allowDarkMode
          sideBarColor
          sideBarTextColor
          useCustomCss
          customCss
          backgroundColor
          timezone
          analyticsId
          enableTellus
          tellusChannels
          autoEnableNotifications
          notificationPrompt
        }
      }`,
      variables () {
        return {
          url: this.domain
        }
      },
      skip () {
        return !this.domain
      }
    },
    environmentUser: {
      query: gql`query getEnvironmentUserByUserId($userId: ID, $environmentId: ID) {
        environmentUser: environmentUserByUserId(userId: $userId, environmentId: $environmentId) {
          _id
          profile
          canAddUsers
          canRemoveUsers
          canEditProfiles
          canManageRoles
          managedRoles
        }
      }`,
      variables () {
        return {
          userId: this.$store.state.auth.user._id,
          environmentId: this.environment._id
        }
      },
      skip () {
        return !this.environment
      },
      result ({ data } : any, key) {
        if (!data.environmentUser) {
          this.noUser = true
        }
      }
    },
    views: {
      query: gql`query getViews($environmentId: ID) {
        views(limit: null, environmentId: $environmentId) {
          items {
            _id
            path
          }
        }
      }`,
      variables () {
        return {
          environmentId: this.environment._id
        }
      },
      skip () {
        return !this.environment
      }
    }
  }
})
export default class EnvironmentRoot extends Vue {
  @Prop({ type: String, required: true }) domain !: string
  @Prop({ type: String, required: true }) path !: string

  environment : Partial<Environment> | null = null
  environmentUser : EnvironmentUser | null = null
  views : PaginatedQueryResult<View> | null = null

  chatInstance : any = null
  notificationCount = 0
  noUser = false

  sidebarOpen = !this.$vuetify.breakpoint.mdAndDown && !this.path || this.path === '/'
  notificationsOpen = false
  messagesOpen = false

  get ready () {
    return !!(this.environment && this.environmentUser)
  }

  get currentView () {
    if (!this.views) return
    return this.views.items.find(v => {
      const match = pathToRegexp.match(v.path)
      return !!match(this.path || '/') || !!match((this.path || '') + '/')
    })
  }

  get currentViewId () {
    return this.currentView && this.currentView._id
  }

  get currentEnvId () {
    return this.environment ? this.environment._id : 'none'
  }

  get sysParams () {
    return {
      date_now: new Date().toISOString()
    }
  }

  get viewParams () {
    if (!this.currentView) return this.sysParams
    const match = pathToRegexp.match(this.currentView.path)
    const result = match(this.path || '/') || match((this.path || '') + '/')
    return result && { ...result.params, ...this.sysParams } || this.sysParams
  }

  get combinedParams () {
    if (!this.environmentUser) return this.viewParams
    return { ..._mapKeys(this.environmentUser.profile, (v,k) => 'user_' + k), ...this.viewParams, ...this.$route.query }
  }

  @Watch('environment')
  async setEnvironmentVariables (env : Environment) {
    const style = document.getElementById('customCSS') as HTMLStyleElement
    if (env.accentColor) {
      this.$vuetify.theme.themes.light.primary = this.$vuetify.theme.themes.light.secondary = this.$vuetify.theme.themes.light.accent = env.accentColor
      this.$vuetify.theme.themes.dark.primary = this.$vuetify.theme.themes.dark.secondary = this.$vuetify.theme.themes.dark.accent = env.accentColor
    }
    if (env.useCustomCss && env.customCss) {
      style.innerText = env.customCss
    } else {
      style.innerText = ''
    }
    if (env.analyticsId) {
      gtag('config', env.analyticsId, {
        'page_path': this.path || '/',
        'mobile_app': window.mobileApp ? 'Yes' : 'No'
      })
    }
  }

  @Watch('environmentUser')
  async setChatVariables (me : EnvironmentUser) {
    const u = this.$store.state.auth.user
    this.chatInstance.setGuestName(u.profile.name || me.profile!.nombre || me.profile!.name || u.email)
    this.chatInstance.setGuestEmail(u.email)
    if (this.environment?.analyticsId) {
      // @ts-ignore
      gtag('set', 'user_properties', {
        user: u.profile.name || me.profile!.nombre || me.profile!.name || u.email
      })
    }
  }

  @Watch('path')
  async reportPath (path : string) {
    if (this.environment?.analyticsId) {
      gtag('config', this.environment.analyticsId, {
        'page_path': '/' + (path || ''),
        'mobile_app': window.mobileApp ? 'Yes' : 'No'
      })
    }
  }

  async logout () {
    await this.$store.dispatch('auth/logout')
    await this.$router.push('/login')
    return
  }

  toggleNotifications () {
    this.notificationsOpen = !this.notificationsOpen
  }

  toggleMessages () {
    this.messagesOpen = !this.messagesOpen
  }

  toggleSidebar () {
    this.sidebarOpen = !this.sidebarOpen
  }

  openSidebar () {
    this.sidebarOpen = true
  }

  closeSidebar () {
    this.sidebarOpen = false
  }
}
