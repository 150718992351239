






























































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { User, ChatConversation, ChatMessage, Environment, EnvironmentUser } from '@/models'
import ChatService from '@/services/ChatService'
import UploadModal from '@/components/fields/file/UploadModal.vue'
import{ FileManagerFile } from '@/models'

@Component({ 
  components: {
    UploadModal
  }
})
export default class EnvironmentMessages extends Vue {

@Prop({ type: Boolean, required: true }) value !: boolean
@Prop({ type: Object, required: true }) environment !: Environment
@Prop({ type: Object, required: true }) environmentUser !: EnvironmentUser

  service : ChatService | null = null

  connection : WebSocket | null = null

  get types () {
    if (!this.environment.tellusChannels || this.environment.tellusChannels.length <= 0) {
      return [
        { label: 'Soporte', value: this.environment._id }
      ]
    }
    return this.environment.tellusChannels.map(c => ({
      label: c.category,
      value: c.channelName
    }))
  }
  
  itemsMenu = [
    { title: 'Terminar sesión' },
  ]

  mode : 'initial' | 'messages' | 'finish' = 'initial'
  ratingView = false

  messageList : ChatMessage[] = []
  newMessage : string = ''
  conversation : any = {}

  subject : string = ''
  shortDescription : string = ''
  rating: number = 0
  comment: string = ''
  uploadModalOpen = false
  buttonLoad = false
  channelName = ''

  openModal () {
    this.uploadModalOpen = true
  }

  async endSession() {
    await this.service!.patchConversation(this.conversation.data.item._id, {status: "closed"})
    this.ratingView = true
    this.scrollDiv()
  }

  handleImageSelect (file : any) {
    this.sendImage(file)
  }

  openConversationSocket(){
    // this.buttonLoad = true
    this.connection = new WebSocket('wss://1ujfthyhh8.execute-api.us-east-1.amazonaws.com/beta')
    this.connection.onopen = async (ev) => {
      console.log('connect')
      this.connection!.send(JSON.stringify({
        action: 'joinConversation',
        data: {
          conversationId: this.conversation.data.item._id
        }
      }))

      // Envía el primer mensaje que es el que describe el problema al inicio
      this.newMessage = this.shortDescription
      this.sendMessage()

      this.connection?.addEventListener('message', (event)  => {
        // Escucha los mensajes
        var objResponse = JSON.parse(event.data)
        var message = objResponse.data as ChatMessage
        if (objResponse.action === 'message'){
          
          // Si llega un action 'message' lo escucha y envía a la lista de mensajes.
          this.messageList.push(message)
          this.scrollDiv()
        } else if (objResponse.action === 'conversationUpdated') {
          if(objResponse.data.status === 'closed')

            // Cuando recibe un action conversationUpdated el response tiene un status y si es closed nos muestra la vista de ratingView
            this.ratingView = true
          }
        })
      this.mode = 'messages'
    }
  }

  async mounted(){
    this.service = new ChatService(this.environment._id, this.$store.getters['auth/session']._id)
    // Obtener la lista de mensajes de la ultima conversacion si esta esta sigue abierta
    try {
      let lastConversation = await this.service.lastConversation()
      if(lastConversation.data.item){
        this.mode = 'messages'
        // Almacena la ultima conversación
        this.conversation = lastConversation
        // Obtengo la lista de mensajes de esa última conversación
        let listMessage = await this.service.getMessages(this.conversation.data.item._id)
        listMessage.data.items.map( (item:any) => {
          this.messageList.push(item)
        })
        this.scrollDiv()
        this.openConversationSocket()
      }
    } catch (e) {
      console.log(e)
    }
  } 

  // Open the conversation
  async sendConversation ( ) {
    
    let firstConversation : ChatConversation = {
      userId: this.user._id,
      username: this.user.email,
      subject: this.subject,
      priority: 1,
      lastMessage: this.shortDescription,
      userAvatar: this.userAvatar,
      environmentId: this.environment._id,
      channelName: this.channelName,
    }

    try {
      this.buttonLoad = true
      
      this.conversation = await this.service!.postConversation(firstConversation)
      let listMessage = await this.service!.getMessages(this.conversation.data.item._id)
      listMessage.data.items.map( (item:any) => {
        this.messageList.push(item)
      })
      this.openConversationSocket();
    } catch (e) {
      console.log(e)
    }
  }

  // Send image
  async sendImage (file : FileManagerFile) {
    let message : ChatMessage = {
      userId: this.user._id,
      // userType: 'codeless', Eliminar esto
      contents: '',
      embed: {
        image: file.url,
        link: file.url,
      }
    }
    try {
      await this.service!.postMessage(this.conversation.data.item._id, message)
    }
    catch(e) {
      console.log(e)
    }
  }

  scrollDiv(){
    const content = this.$refs.container as HTMLDivElement
    content.scrollTop = content.scrollHeight
  }

  // Send newMessage
  async sendMessage () {

    if(this.newMessage.length === 0) return

    let message : ChatMessage = {
      userId: this.user._id,
      userType: 'codeless',
      contents: this.newMessage
    }
    try {
      this.newMessage = ''
      await this.service!.postMessage(this.conversation.data.item._id , message)
      this.scrollDiv()
    }
    catch(e) {
      console.log(e)
    }
  }

  cleanData() {
    this.ratingView = false
    this.newMessage = ''
    this.conversation = {}
    this.messageList = []
    this.subject = ''
    this.shortDescription = ''
    this.rating = 0
    this.comment = ''
    this.connection = null
    this.buttonLoad = false
  }

  async sendRating() {
    let rating : ChatConversation = {
      userRating: this.rating,
      userFeedback: this.comment
    }
    try {
      this.mode = 'finish'
      let response = await this.service!.patchConversation(this.conversation.data.item._id, rating)
      this.cleanData()
      // this.myMessages.push(message) 
    }
    catch(e) {
      console.log(e)
    }
  }

  get user (): User {
    return this.$store.state.auth.user
  }

  get userAvatar (): string {
    return this.$store.getters['auth/userAvatarURL']
  }
  // Open navigation-drawer chat
  set open (v : boolean) {
    this.$emit('input', v)
  }

  get open () {
    return this.value
  }
}
