import axios, { AxiosInstance } from 'axios';
import store from '../store/index'

import { ChatConversation, ChatMessage } from '@/models/Chat'

// https://tellus-beta.services.simplex.biz

export default class ChatService {
  apiChat : AxiosInstance
  
  constructor (
    public environmentId : string,
    public sessionId : string
  ) {
    this.apiChat = axios.create({
      baseURL: 'https://tellus-beta.services.simplex.biz',
      withCredentials: false,
      headers: {
        Accept: 'applicacion/json',
        'Content-Type': 'application/jso',
        'Authorization': `Codeless ${environmentId} ${sessionId}`
      },
    })
  }

  lastConversation() {
    return this.apiChat.get(`/api/conversations/last`)
  }

  getMessages (conversationId : string) {
    return this.apiChat.get(`/api/conversations/${conversationId}/messages`)
  }

  postMessage (conversationId : string, value : ChatMessage) {
    return this.apiChat.post(`/api/conversations/${conversationId}/messages`, value)
  }

  postConversation (value : ChatConversation) {
    return this.apiChat.post('/api/conversations', value)
  }

  patchConversation (conversationId : string, value: any) {
    return this.apiChat.patch(`/api/conversations/${conversationId}`, value)
  }
}
