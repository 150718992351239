















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Environment, EnvironmentUser, Link } from '@/models'
import Loading from '@/components/Loading.vue'
import _snakeCase from 'lodash/snakeCase'
import gql from 'graphql-tag'
import resolveLink from '@/utils/route/resolveLink'

@Component({
  components: {
    Loading
  },
  filters: {
    fixLegacyIcons (input : string) {
      if (input && input.indexOf('Md') === 0) {
        return _snakeCase(input.slice(2))
      }
      return input
    }
  },
  apollo: {
    sideBar: {
      query: gql`query ($environmentId : ID) {
        sideBar(environmentId: $environmentId)
      }`,
      variables () {
        return {
          environmentId: this.environment._id
        }
      }
    }
  }
})
export default class EnvironmenSidebar extends Vue {
  @Prop({ type: Object, required: true }) environment !: Environment
  @Prop({ type: Object, required: true }) environmentUser !: EnvironmentUser
  @Prop({ type: String, default: '' }) path !: string
  @Prop({ type: Boolean, default: true }) value !: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams !: Record<string, string>

  sideBar : Link[] = []
  collapsing ?: boolean = false

  @Watch('mini')
  watchCollapse (m : boolean) {
    if (!m) return
    this.collapsing = true
    this.$nextTick(() => {
      this.collapsing = false
    })
  }

  collapse () {
    if (this.mini) return
    this.collapsing = true
    this.$nextTick(() => {
      this.collapsing = false
    })
    setTimeout(() => {
      this.mini = true
    }, 100)
  }

  get sidebarStyle () {
    return {
      'z-index': 100,
      color: this.environment.sideBarTextColor,
      background: this.environment.sideBarColor
    }
  }

  get resolvedLinks () {
    return this.sideBar.map(link =>
      link.path ? {
        ...link,
        path: resolveLink(link.path, this.viewParams).href
      } : {
        ...link,
        fields: link.fields!.map(subLink => ({
          ...subLink,
          path: resolveLink(subLink.path, this.viewParams).href
        }))
      }
    )
  }

  mobileToggle (v : boolean) {
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.$emit('input', v)
    }
  }

  get mini () {
    if (this.$vuetify.breakpoint.mdAndDown || !this.path || this.path === '/') return false
    return !this.value
  }

  set mini (v : boolean) {
    this.$emit('input', !v)
  }
}
